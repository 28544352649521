import select2 from 'select2';

import 'select2/dist/css/select2.css';
import 'select2-bootstrap-theme/dist/select2-bootstrap.min.css'
//https://www.npmjs.com/package/select2-bootstrap-theme

select2($);

//  $(document).ready(function() {
//   $('select').select2();
//  });

//document.addEventListener('turbo:load', () => { 
document.addEventListener("turbo:load", function(){
 // $(document).ready(function(){
    //$(document).onload(function(){
      if ($(".select-diagnosis")) {
        $(".select-diagnosis").select2({
          theme: 'bootstrap',
          allowClear: false,
          placeholder: 'Start typing...',
          minimumInputLength: 1,
          dataType: 'json',
          ajax: {
            url: '/icd10/search',
            delay: 250,
            data: function(params) {
              return { code: params.term }
            },
            processResults: function (data, params) {
              return {
                results: $.map(data, function(value, index){
                  return {id: value.id, full_code: value.full_code ,text: `${value.full_code} (${value.abbreviated_description})`};
                  // return {id: value.id, text: value.full_code };
                })
              };
              //cache: true
            }
          }
        });
     }
    });
    


 //   $(document).ready(function() {  // this sysntax is deprecated
 $(document).on('turbo:load', function() {
      $('.fancy-select').select2({
        theme: 'bootstrap'
      }
      ); 
    });